body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: 1.1em;
}

.section {
  padding-top: 40px;
  padding-bottom: 40px;
}
.carousel-item img {
  object-fit: fill;
  object-position: center;
  height: 37vh;
  overflow: hidden;
  display: block;
}
table {
  font-size: 0.85rem;
  font-family: Arial, Helvetica, sans-serif;
}
.nopol {
  font-size: 0.85rem;
  font-weight: bold;
  background-color: orange;
}
.partlist {
  font-size: 0.85rem;
}

