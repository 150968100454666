.section {
  padding-top: 40px;
  padding-bottom: 40px;
}
.carousel-item img {
  object-fit: fill;
  object-position: center;
  height: 37vh;
  overflow: hidden;
  display: block;
}
table {
  font-size: 0.85rem;
  font-family: Arial, Helvetica, sans-serif;
}
.nopol {
  font-size: 0.85rem;
  font-weight: bold;
  background-color: orange;
}
.partlist {
  font-size: 0.85rem;
}
